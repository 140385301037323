<template>
  <b-card-code title="Email Security">
    <b-table responsive id="esTable" :fields="fields" :items="items">
      <template #cell(asset)="data">
        <router-link :to="'/assets/scan/' + data.item.asset_id">{{
          data.item.asset
        }}</router-link>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "asset", label: "Asset" },
        // { key: "ip", label: "Ip" },
        { key: "spf", label: "SPF" },
        { key: "mx", label: "MX" },
        { key: "dkim", label: "DKIM" },
        { key: "dmarc", label: "DMARC" },
      ],
      items: [],
    };
  },
  created: function () {
    if (this.org_id != null) {
      this.load();
    }
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "dashboard/v1/email-security?org_id=" +
          this.org_id,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>