<template>
  <b-row class="">
    <b-col cols="12">
      <div>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          layout="vertical"
          finish-button="Submit"
          back-button-text="Previous"
          class="wizard-vertical mb-3"
          v-model="activeTab"
          ref="wizard"
        >
          <!-- asset datails tab -->
          <tab-content title="Add Asset">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Asset Details</h5>
                <small class="text-muted"> Enter Your Asset Details.</small>
              </b-col>
              <b-col cols="12" class="ml-auto mr-auto">
                <b-card class="border-primary rounded">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Asset Name" label-for="v-username">
                        <b-form-input
                          id="asset_name"
                          v-model="asset_name"
                          name="asset_name"
                        />
                      </b-form-group>
                      <!-- {{ asset_name }} -->
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="URL" label-for="v-url">
                        <b-form-input
                          id="v-url"
                          v-model="assetURL"
                          name="assetURL"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12 d-flex justify-content-center">
                      <b-button variant="primary" size="sm" @click="addAsset()">
                        Add
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>

          <!-- verify info tab -->
          <tab-content title="Verify Domain">
            <b-row>
              <b-col md="12">
                <b-tabs ref="verficationTabs">
                  <b-tab title="File Verification" active>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">By manual file upload</h5>
                        <small class="text-muted"
                          >Verify domain ownership by manual file upload</small
                        >
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <p>
                            Download the file on your computer using the link
                            provided below Place the text file in the root of
                            the website directory. Need help? Check out
                            <a href="">this article</a>
                          </p>
                          <p>
                            Secusy will try to access the file at the following
                            location:
                          </p>
                          <b-form-input v-model="fileVerification" disabled />
                          <p class="text-muted">
                            Click on the Verify domain button to complete the
                            domain verification process
                          </p>
                          <div class="d-flex justify-content-center">
                            <a
                              href="_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt"
                              download
                            >
                              <b-button variant="primary" size="sm">
                                Download
                              </b-button>
                            </a>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="gotoverficationTabs()"
                        >
                          Skip
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="getfileVerification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="DNS Verification" lazy>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">
                          Add the below TXT details as your DNS entry
                        </h5>
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label="Type" label-for="v-txt">
                                <b-form-input
                                  id="v-txt"
                                  placeholder="TXT"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group label="Name" label-for="v-name">
                                <b-form-input
                                  id="v-name"
                                  disabled
                                  v-model="recod_name"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group label="Value" label-for="v-value">
                                <b-form-input
                                  id="v-value"
                                  disabled
                                  v-model="expected_value"
                                />
                              </b-form-group>
                            </b-col>
                            <!-- <b-col md="12">
                              <b-form-group
                                label="Secusy will check for the DNS TXT record at"
                                label-for="v-dns"
                              >
                                <b-form-input
                                  id="v-dns"
                                  disabled
                                  placeholder="nXzLcDSvLCn55pG1Zm0xfezQfrAUDEqa"
                                />
                              </b-form-group>
                            </b-col> -->
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="gotoverficationTabs()"
                        >
                          Skip
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="getDNSverification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="HTML Verification" lazy>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">
                          Copy and paste the below snippet within the head tag
                          in the HTML of your site's homepage
                        </h5>
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label-for="v-meta">
                                <b-form-input
                                  id="v-meta"
                                  disabled
                                  v-model="HTMLVerification"
                                />
                              </b-form-group>
                              <p class="text-muted">
                                Save and confirm that tag is present in the live
                                page
                              </p>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <!-- <b-button variant="outline-secondary" size="sm" @click="gotoverficationTabs()">
                          Skip
                        </b-button> -->
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class=""
                          @click="getHTMLverification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </tab-content>

          <!-- start test -->
          <tab-content title="Start Test">
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-card class="border-primary rounded">
                  <b-row>
                    <b-col cols="12" class="mb-2 d-flex justify-content-center">
                      <feather-icon
                        icon="ShieldIcon"
                        class="ml-50 text-primary"
                        size="50"
                      />
                    </b-col>
                    <b-col cols="12" class="mb-2 d-flex justify-content-center">
                      <p class="text-primary">You can start the test now</p>
                    </b-col>
                    <b-col cols="12" class="d-flex justify-content-center">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="startTest()"
                        :disabled="disableBtn"
                      >
                        <span v-if="isloading"
                          ><b-spinner type="border" small></b-spinner> Please
                          wait</span
                        >
                        <span v-else> Start Test</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
        <!-- <button @click="goToLastTab">Go to Last Tab</button> -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      activeTab: 0,
      is_staff: this.$store.state.app.user.is_staff,
      assetURL: "",
      asset_name: "",
      asset_owner_id: "",
      scanners: [],

      fileVerification: "_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt",
      fVerification: "",
      DNSVerification: "",
      recod_name: "_cKg6Xp9wDq2Rv4Tc3Nj8Ym5Lh1FbGsZx",
      expected_value: "_k2Pb8cN5rDqX9kZj7Mv3Fg6TtWxL1hYsR",
      HTMLVerification:
        '<meta name="_j5aB8Rt2Yn6qXpDg9Ju4F7h3KcV2xLwNt" , signature="_c9W3cJ5mR8xQ1vF6zK2nD7pB4hT0gXyLw" />',
      org_id: this.$store.state.app.organizationId,
      assetId: null,
      is_verified: false,
      isloading: "",
      disableBtn: false,
    };
  },
  methods: {
    // formSubmitted() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Form Submitted",
    //       icon: "EditIcon",
    //       variant: "success",
    //     },
    //   });
    // },
    gotoverficationTabs() {
      this.$refs.verficationTabs.nextTab();
    },
    goToLastTab() {
      const totalTabs = this.$refs.wizard.tabs.length;
      for (let i = 0; i < totalTabs - 1; i++) {
        this.$refs.wizard.nextTab();
      }
    },

    addAsset() {
      if (!this.is_staff) this.asset_owner_id = this.$store.state.app.user.id;
      let data = {
        asset_name: this.assetURL,
        asset_nickname: this.asset_name,
        asset_type: 2,
        asset_owner_id: this.asset_owner_id,
      };
      let scanners = [];
      data.scanners = JSON.stringify(scanners);
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/",
      };
      this.$http(options).then((res) => {
        // console.log(res);
        if (res.data.asset_id) {
          (this.assetId = res.data.asset_id),
            (this.is_verified = res.data.is_verified),
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Asset Created Successfully",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
          if (this.is_verified) {
            this.goToLastTab();
          } else {
            this.$refs.wizard.nextTab();
          }
        }
        if (res.data.non_field_errors) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.non_field_errors[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
      // this.isloading = false;
    },
    getfileVerification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/file-verification",
      }).then((res) => {
        this.fVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // if (this.is_verified) {
          //   this.goToLastTab();
          // } else {
          //   this.$refs.wizard.nextTab();
          // }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    getDNSverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/verify-dns",
      }).then((res) => {
        this.DNSVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    getHTMLverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/html-verification",
      }).then((res) => {
        // this.HTMLVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    startTest() {
      (this.disableBtn = true),
        (this.isloading = true),
        this.$http({
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset/" +
            this.assetId +
            "/start-testing",
        }).then((res) => {
          // this.teststart = res.data;
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push(`/assets/scan/${this.assetId}`);

            // this.$router.push({ name: "Assets" });
          }
          this.disableBtn = false;
          this.isloading = false;
        })
    },
  },
};
</script>
<style lang="scss">
.mt-10 {
  margin-top: 10rem !important;
}
@import "@core/scss/vue/libs/vue-wizard.scss";

// .form-wizard .wizard-buttons {
//   display: none !important;
// }
[dir="ltr"] .vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
  display: none !important;
}
</style>
