<template>
  <!-- <b-card class="bg-light-secondary"> -->
  <div>
    <div class="d-flex">
      <span class="d-flex" style="width: 100%">
        <v-select
          v-model="assetName"
          label="text"
          :options="asset_options"
          placeholder="--Search Asset--"
          @search="fetchOptions"
          @input="loadAgentTypes"
          autocomplete
          class="w-100"
          :reduce="(assetName) => assetName.value"
        />
        <b-button
          class="btn-radius-hide-left"
          variant="outline-success"
          size="sm"
          @click="gotoAddAsset()"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.top="'Add New Asset'"
          ><feather-icon icon="PlusIcon"
        /></b-button>
      </span>
    </div>
    <div class="d-flex justify-content-end my-1">
      <b-button
        style="width: 20%"
        class="ml-1"
        variant="primary"
        @click="openScanModal()"
        :disabled="assetName === null"
        size="sm"
      >
        <span v-if="isloading"
          ><b-spinner type="border" small></b-spinner> Please wait</span
        >
        <span v-else>
          <feather-icon icon="SearchIcon" class="mr-50" />Scan</span
        >
      </b-button>
      <b-button
        style="width: 20%"
        class="ml-1"
        variant="primary"
        @click="openScheduleModal"
        :disabled="assetName === null"
        size="sm"
      >
        <feather-icon icon="ClockIcon" class="mr-50" />Schedule
      </b-button>
    </div>
    <b-modal
      ref="modal-add-asset"
      title="Add Asset"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddAssetOption
        :closeSidebar="closeModal"
        :assetRedirct="assetRedirct"
        :isHide="true"
      />
    </b-modal>
    <b-modal
      ref="modal-normal-scan"
      title="Scan Options"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddNormalScan
        :agent_type_id="agentId"
        :asset_id="assetName"
        :callBack="afterScan"
        :agent_type="agentType"
      />
    </b-modal>
    <b-modal
      ref="modal-schedule-scan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AttachScheduler
        :agent_type_id="agentId"
        :asset_id="assetName"
        :callBack="afterScan"
        :agent_type="agentType"
      />
    </b-modal>
  </div>
  <!-- </b-card> -->
</template>
<script>
import { BCard, BRow, BCol, BButton, BModal, VBTooltip } from "bootstrap-vue";
import AddAssetOption from "../../AssetGroup/componets/addAssetOption.vue";
import AddNormalScan from "../../Asset/components/AddNormalScan.vue";
import AttachScheduler from "../../Asset/components/attach_scheduler.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BCard,
    vSelect,
    AddAssetOption,
    BModal,
    AddNormalScan,
    AttachScheduler,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      assetName: null,
      asset_options: [],
      org_id: this.$store.state.app.org_id,
      assetRedirct: true,
      agent_items: [],
      agentId: null,
      agentType: null,
    };
  },
  props: {
    closeASScan: {
      type: Function,
      required: true,
    },
  },
  created: function () {},
  methods: {
    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type?query=true",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        console.log(res.data, "agent type list");
        this.agent_items = res.data.filter(
          (item) => item.agent_type_code === "attack_surface"
        );

        const agentTypeInfo = this.agent_items.map((item) => ({
          agentType: item.agent_type_code,
          agentId: item.agent_type_id,
        }));

        this.agentType = agentTypeInfo.map((info) => info.agentType)[0] || "";
        this.agentId = agentTypeInfo.map((info) => info.agentId)[0] || null;
      });
    },
    gotoAddAsset() {
      this.$refs["modal-add-asset"].show();
    },
    closeModal() {
      this.$refs["modal-add-asset"].hide();
    },
    openScanModal: function () {
      this.$refs["modal-normal-scan"].show();
    },
    openScheduleModal: function (id, type) {
      this.$refs["modal-schedule-scan"].show();
    },
    afterScan: function (callBackParams) {
      this.$refs[callBackParams].hide();
      this.assetName = null;
      this.closeModal();
      this.closeASScan();
    },
  },
};
</script>
<style lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.5; /* or any other styling to indicate disabled state */
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
