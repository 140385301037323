<template>
  <b-card no-body>
    <b-card-header>
      <!-- {{ values }} -->
      <b-card-title class="text-primary">Upcoming Scans</b-card-title>
    </b-card-header>
    <b-table responsive :fields="fields" :items="upcomingScans">
      <template #cell(3)="data">
        <!-- <div class="d-flex justify-content-center"> -->
        <b-badge size="sm" variant="light-primary">{{ data.item[3] }}</b-badge>
        <!-- </div> -->
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardTitle,
  },
  props: {
    upcomingScans: {
      type: Array,
      required: true,
    },
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      // Call orgaization API and return in this format
      items: [],
      fields: [
        { key: "2", label: "Target" },
        { key: "3", label: "Agent Name" },
      ],
    };
  },
  // created: function () {
  //   if (!this.$store.state.app.user.is_staff) {
  //     this.load();
  //   }
  // },
  // mounted() {
  //   if (this.org_id != null) {
  //     this.load();
  //   }
  // },
  //   methods: {
  //     load: function () {
  //       const options = {
  //         method: "GET",
  //         headers: { "content-type": "application/json" },
  //         url:
  //           process.env.VUE_APP_BASEURL +
  //           "dashboard/v2/recent-scans?org_id=" +
  //           this.org_id,
  //       };
  //       this.$http(options).then((res) => {
  //         this.items = res.data;
  //       });
  //     },
  //   },
};
</script>