var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":""}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/assets',
              query: { grouptype: 'external' },
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.asset_based_data.total_external_asset_count))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Exposed Assets")])])]),_c('b-col',{attrs:{"cols":""}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/dashboard/domain-list',
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.asset_based_data.domains_subdomains))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Domains & Sub Domains")])])]),_c('b-col',{attrs:{"cols":""}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/assets/vulnerabilities',
              query: {
                asm_filter: 1,
                tabIndex: 1,
              },
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.vulnerability_count))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Open Vulnerabilities")])])]),_c('b-col',{attrs:{"cols":""}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/assets/vulnerabilities',
              query: {
                asm_filter: 1,
                tabIndex: 1,
                source: 'SSL_EXPIRY',
              },
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.expired_certificates))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Expired Certificates")])])]),_c('b-col',{attrs:{"cols":""}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/assets/vulnerabilities',
              query: {
                asm_filter: 1,
                tabIndex: 1,
                source: 'INSECUREPORT',
              },
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.insecure_ports))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Insecure Ports")])])]),_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-info",modifiers:{"hover":true,"v-info":true}}],attrs:{"cols":"","title":"Breached - Not Addressed"}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/Discovery',
              query: { tabIndex: 2, breached: 1 },
            })}}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.attack_surface_data.breached_emails))]),_c('br'),_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px"}},[_vm._v("Breached Emails")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }