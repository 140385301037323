<template>
  <b-card-code title="Insecure Port">
    <!-- {{ items }} -->
    <b-table responsive id="portTable" :fields="fields" :items="items">
      <template #cell(name)="data">
         <router-link :to="'/vulnerability/detail/' + data.item.id">{{
          data.item.name
        }}</router-link>
      </template>
      <template v-slot:cell(severity)="data">
        <b-badge
          style="background-color: #991d28"
          v-if="data.item.severity == 'critical'"
          >Critical</b-badge
        >
        <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
          >High</b-badge
        >
        <b-badge variant="warning" v-else-if="data.item.severity == 'medium'"
          >Medium</b-badge
        >
        <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
          >Low</b-badge
        >
        <b-badge variant="success" v-else-if="data.item.severity == 'info'"
          >Info</b-badge
        >
        <b-badge variant="secondary" v-else>Unknown</b-badge>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable,BBadge } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "severity", label: "Severity" },
        // { key: "actions", label: "Actions" },
      ],
      items: [],
    };
  },
  created: function () {
    if (this.org_id != null) {
      this.load();
    }
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "dashboard/v1/insecure-ports?org_id=" +
          this.org_id,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>