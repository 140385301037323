<template>
  <div>
    <div class="row" v-if="!isLoadingAS">
      <div class="col-12">
        <!-- <div class="d-flex justify-content-end mb-1">
          <b-button
          @click="gotoScan()"
          type="button"
          variant="outline-primary"
          class="mr-1"
          size="sm"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.top="'Perform Attack Surface'"
        >
          + Attack Surface Scan
        </b-button>
        </div> -->
        <div class="d-flex justify-content-end mb-1">
          <b-button
            @click="downloadPDFReport()"
            type="button"
            variant="outline-primary"
            class="mr-1"
            size="sm"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Download PDF Report'"
            :disabled="is_disable"
          >
            <span v-if="is_loading_pdf"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Download Report</span>
          </b-button>
        </div>
        <b-card>
          <AttackSurfaceGeneralCount
            :attack_surface_data="attack_surface_data"
          />
        </b-card>
      </div>
      <dir class="col-12">
        <b-card-group deck>
          <b-card no-body>
            <ManagedVsUnmanaged
              :values="managedandunmanagedvalues"
              :total_count="
                attack_surface_data.asset_based_data.total_external_asset_count
              "
            />
          </b-card>
          <b-card no-body>
            <AssetByRIsk
              :values="riskProfile"
              :total_count="
                attack_surface_data.asset_based_data.managed_assets_count
              "
            />
          </b-card>
          <b-card no-body>
            <DomainDistribution :keys="keys" :values="values" />
          </b-card>
          <b-card no-body>
            <UnsanctionedServices
              :unsactionItems="attack_surface_data.unsanctioned_services"
            />
          </b-card>
        </b-card-group>
      </dir>
      <div class="col-8">
        <!-- <b-card> -->
        <AttackSurfaceByLocation
          :attackSurfaceLocation="attack_surface_data.attacksurface_by_location"
        />
        <!-- </b-card> -->
      </div>
      <div class="col-4">
        <ExtenalExposedAssets :exposedAsset="exposedAsset" />
      </div>
    </div>
    <div v-if="isLoadingAS" class="d-flex justify-content-center mb-1">
      <b-spinner class="float-right" label="Floated Right" />
    </div>

    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-card-title class="text-primary">Attack Surface</b-card-title>
      </div>

      <div v-if="isloadingtable" class="d-flex justify-content-center mb-1">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <div v-else>
        <b-table-simple
          v-if="items.length"
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>#</b-th>
              <b-th class="text-center">IP Address</b-th>
              <!-- <b-th>OS</b-th> -->
              <b-th>Asset Name</b-th>
              <b-th>Port</b-th>
              <b-th>Protocol</b-th>
              <b-th style="white-space: nowrap">Vulnerability Count</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-for="(result, index) in items" :key="index">
            <b-tr>
              <b-td :rowspan="result.nmap_result.ports.length + 1">
                {{ index + 1 + perPage * (currentPage - 1) }}
              </b-td>
              <b-td
                :rowspan="result.nmap_result.ports.length + 1"
                class="text-center"
              >
                {{ result.nmap_result.address }}
              </b-td>
              <b-td :rowspan="result.nmap_result.ports.length + 1">
                <router-link :to="'/assets/scan/' + result.asset_id">{{
                  result.asset_name
                }}</router-link></b-td
              >
            </b-tr>
            <b-tr
              v-for="(nmapPort, npid) in result.nmap_result.ports"
              :key="npid"
            >
              <b-td> {{ nmapPort.portid }}/{{ nmapPort.protocol }} </b-td>
              <b-td>{{ nmapPort.service_name }}</b-td>
              <b-td style="white-space: nowrap">
                <span
                  class="align-items-center"
                  v-for="(key, value) in result.vulnerabilities_counts[
                    nmapPort.portid
                  ]"
                  :key="key"
                >
                  <b-avatar
                    v-if="value === 'critical'"
                    style="background-color: #a020f0 !important"
                    class="cursor-pointer text-capitalize"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top
                    :title="value.charAt(0).toUpperCase() + value.slice(1)"
                    button
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          port: nmapPort.portid,
                          asset_name: result.asset_name,
                          severity: 'critical',
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    {{ key }}
                  </b-avatar>
                  <b-avatar
                    v-if="value === 'high'"
                    variant="danger"
                    class="cursor-pointer text-capitalize"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top
                    :title="value.charAt(0).toUpperCase() + value.slice(1)"
                    button
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          port: nmapPort.portid,
                          asset_name: result.asset_name,
                          severity: 'high',
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    {{ key }}
                  </b-avatar>
                  <b-avatar
                    v-if="value === 'medium'"
                    variant="warning"
                    class="cursor-pointer text-capitalize"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top
                    :title="value.charAt(0).toUpperCase() + value.slice(1)"
                    button
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          port: nmapPort.portid,
                          asset_name: result.asset_name,
                          severity: 'medium',
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    {{ key }}
                  </b-avatar>
                  <b-avatar
                    v-if="value === 'low'"
                    variant="success"
                    class="cursor-pointer text-capitalize"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top
                    :title="value.charAt(0).toUpperCase() + value.slice(1)"
                    button
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          port: nmapPort.portid,
                          asset_name: result.asset_name,
                          severity: 'low',
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    {{ key }}
                  </b-avatar>
                  <b-avatar
                    v-if="value === 'info'"
                    style="background-color: #7367f0 !important"
                    class="cursor-pointer text-capitalize"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top
                    :title="value.charAt(0).toUpperCase() + value.slice(1)"
                    button
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          port: nmapPort.portid,
                          asset_name: result.asset_name,
                          severity: 'info',
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    {{ key }}
                  </b-avatar>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end py-1" v-if="items.length">
        <router-link class="text-white" :to="'/attack-surface'">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            style="position: relative"
          >
            See More >>
          </button>
        </router-link>
      </div>
    </b-card>
    <b-modal
      v-model="openScanModal"
      ref="modal-attack-surface-scan"
      title="Attack Surface Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AttackSurfaceScanner :closeASScan="closeASScan" />
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCardGroup,
  BCard,
  BTable,
  BBadge,
  BButton,
  BImage,
  BModal,
  BCardText,
  BSpinner,
  BPaginationNav,
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BTableColumn,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormInput,
  VBTooltip,
  BTab,
  BTabs,
  BAvatar,
  BCardTitle,
} from "bootstrap-vue";
import moment from "moment";
import BSTableSimple from "@/components/bs_table/TableSimple";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AttackSurfaceGeneralCount from "./AttackSurfaceGraph/attackSurfaceGeneralCount.vue";
import ManagedVsUnmanaged from "./AttackSurfaceGraph/managedVsUnmanaged.vue";
import AssetByRIsk from "./AttackSurfaceGraph/assetByRisk.vue";
import UnsanctionedServices from "./AttackSurfaceGraph/unsanctionedServices.vue";
import AttackSurfaceByLocation from "./AttackSurfaceGraph/attackSurfaceByLocation.vue";
import DomainDistribution from "./AttackSurfaceGraph/domainDistribution.vue";
import ExtenalExposedAssets from "./AttackSurfaceGraph/externalExposedAssets.vue";
import AttackSurfaceScanner from "./AttackSurfaceGraph/attackSurfaceScanner.vue";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BImage,
    BModal,
    BCardText,
    BSpinner,
    BPaginationNav,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BTableColumn,
    BSTableSimple,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardCode,
    BFormInput,
    vSelect,
    BTab,
    BTabs,
    BAvatar,
    BCardTitle,
    BCardGroup,
    AttackSurfaceGeneralCount,
    ManagedVsUnmanaged,
    AssetByRIsk,
    UnsanctionedServices,
    AttackSurfaceByLocation,
    DomainDistribution,
    ExtenalExposedAssets,
    AttackSurfaceScanner,
  },
  props: {
    // attackSurfaceTab: {
    //   type: Boolean,
    //   required: true,
    // },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      total_assets_count: 0,
      items: [],
      fields: [
        { label: "#", visible: true },
        { label: "IP Address", visible: true },
        { label: "OS", visible: true },
        { label: "Port", visible: true },
        { label: "Protocol", visible: true },
        { label: "URL", visible: false },
        { label: "Technology", visible: true },
      ],
      xfields: [
        { key: "index", label: "#", visible: true },
        // { key: "nmap_result", label: "Nmaper", visible: true },
        // { key: "whatweb_result", label: "WhatWeb", visible: true },
        { key: "address", label: "IP Address", visible: true },
        { key: "asset_name", label: "Hostname", visible: true },
        { key: "os", label: "OS", visible: false },
        // { key: "country", label: "country", visible: true },
        // { key: "addrtype", label: "Type", visible: true },

        // { key: "ports", label: "Port", visible: false },
        { key: "protocol", label: "Protocol", visible: true },
        { key: "technology", label: "Technology", visible: true },
        { key: "url", label: "URL", visible: false },
      ],
      loading: false,
      name_filter: null,
      ip_address_filter: null,
      isloading: false,
      imageUrls: [],
      assetName: null,
      asset_options: [],
      org_id: this.$store.state.app.org_id,
      agent_items: [],
      agentId: null,
      agentType: null,
      assetRedirct: true,
      searchDisable: false,
      isloadingtable: false,
      isLoadingAS: false,
      // tabIndex:0,
      attack_surface_data: {},
      managedandunmanagedvalues: [],
      riskProfile: [],
      domainDistibution: [],
      keys: [],
      values: [],
      exposedAsset: [],
      openScanModal: false,
      is_loading_pdf: false,
      is_disable: false,
    };
  },
  watch: {
    tabIndex: function (newVal, oldVal) {
      if (newVal === 2 || oldVal === 2) {
        this.load();
        this.loadAttackSurfaceData();
      }
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created() {
    if (this.tabIndex === 2) {
      this.load();
      this.loadAttackSurfaceData();
    }
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    gotoScan() {
      this.openScanModal = true;
    },
    closeASScan() {
      this.openScanModal = false;
    },
    load: function () {
      this.isloadingtable = true;
      let url = process.env.VUE_APP_BASEURL + "dashboard/v2/attack-surface";
      if (this.name_filter != null) {
        url = url + "&asset_name=" + this.name_filter;
      }
      if (this.ip_address_filter != null) {
        url = url + "&ip_address=" + this.ip_address_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data.attack_surface_data;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_assets_count = res.data.count;
        this.isloadingtable = false;
      });
    },
    loadAttackSurfaceData: function () {
      this.isLoadingAS = true;
      let url = process.env.VUE_APP_BASEURL + "dashboard/v3/asm-dashboard";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.attack_surface_data = res.data;
        this.managedandunmanagedvalues = [
          self.attack_surface_data.asset_based_data.managed_assets_count,
          self.attack_surface_data.asset_based_data.unmanaged_asset_count,
        ];
        this.riskProfile = [
          self.attack_surface_data.asset_based_data.risk_profile
            .critical_risk_assets,
          self.attack_surface_data.asset_based_data.risk_profile
            .high_risk_assets,
          self.attack_surface_data.asset_based_data.risk_profile
            .medium_risk_assets,
          self.attack_surface_data.asset_based_data.risk_profile
            .low_risk_assets,
        ];
        this.exposedAsset = self.attack_surface_data.expossed_assets;
        this.domainDistribution = [
          self.attack_surface_data.domain_distributions,
        ];

        // Extracting keys and values
        const dataObject = this.domainDistribution[0]; // Assuming there's only one object in the array
        this.keys = Object.keys(dataObject);
        this.values = Object.values(dataObject);
        this.isLoadingAS = false;
      });
    },
    downloadPDFReport() {
      this.is_loading_pdf = true;
      this.is_disable = true;
      var fileName = "attacksurface_report.pdf";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "dashboard/v3/asm-report",
        responseType: "blob",
      };
      this.$http(options)
        .then((res) => {
          if (res.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "connection error",
                icon: "EditIcon",
                variant: "danger",
              },
            });
            this.is_loading_pdf = false;
            this.is_disable = false;
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "PDF report download has been completed successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.is_loading_pdf = false;
            this.is_disable = false;
          }
        })
        .catch((err) => {
          this.is_loading_pdf = false;
          this.is_disable = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .dark-layout .vs__dropdown-toggle {
  background: #283046;
  border-color: #3b4253;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.vs__dropdown-toggle {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-radius-hide-left {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #d8d6de !important;
}
[dir] .dark-layout .btn-radius-hide-left {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #3b4253 !important;
}
</style>