<template>
  <div>
    <!-- <b-card no-body class="pb-2"> -->
    <b-card-header>
      <b-card-title style="font-size: 14px"
        >Unsactioned Exposed Services</b-card-title
      >
    </b-card-header>
    <b-table
      class="backgroundRed"
      responsive
      id="unsactionedserviceTable"
      :fields="fields"
      :items="getServiceData"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template v-slot:head(value)>
        <div
          class="d-flex justify-content-center"
          style="white-space: nowrap; overflow: hidden"
        >
          <span style="text-overflow: ellipsis">Count</span>
        </div>
      </template>
      <template #cell(key)="data">
        <a @click="gotoBaseline(data.item.key)">
          {{ data.item.key }}
        </a>
      </template>
      <template #cell(value)="data">
        <div class="d-flex justify-content-center">
          <b-avatar
            size="md"
            variant="secondary"
            :text="data.item.value ? data.item.value : '0'"
          />
        </div>
      </template>
    </b-table>
    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="unsactionedserviceTable"
      :align="pagination_pos"
    ></b-pagination> -->
  </div>
  <!-- </b-card> -->
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BPagination,
  },
  props: {
    unsactionItems: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getServiceData() {
      return Object.entries(this.unsactionItems).map(([key, value]) => ({
        key,
        value,
      }));
    },
    rows() {
      return this.getServiceData.length;
    },
  },
  data() {
    return {
      pagination_pos: "center",
      currentPage: 1,
      perPage: 5,
      total: 1,
      items: [],
      fields: [
        { key: "key", label: "Services" },
        { key: "value", label: "Asset Count" },
      ],
    };
  },
  methods: {
    gotoBaseline(key) {
      const serviceName = key;
      this.$router.push({
        path: "/dashboard/baseline-list",
        query: {
          service_name: serviceName,
        },
      });
    },
  },
};
</script>
<style lang="scss" >
[dir] .dark-layout .table {
  background-color: transparent !important;
}
</style>