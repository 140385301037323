<template>
  <div>
    <b-card class="border-primary rounded">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h4 class="text-primary text-left">Asset Overview</h4>
        <b-button
          @click="gotoaddAsset()"
          type="button"
          variant="outline-primary"
          class="mr-1"
          size="sm"
        >
          Add Asset
        </b-button>
      </div>
      <b-row>
        <b-col lg="12">
          <b-row>
            <b-col>
              <!-- <b-card class="border-success rounded text-center mb-0 pad-0 py-0">
              <b-card-body class="p-0 py-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { assettype: 0, group_id: groupid },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.total_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.total_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Total</p>
              </div>
              <!-- </b-card-body>
            </b-card> -->
            </b-col>
            <b-col>
              <!-- <b-card class="border-danger rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { grouptype: 'external', group_id: groupid },
                  })
                "
              >
                <!-- @click="
                  $router.push({
                    path: '/assets/',
                    query: { asset_type: 'internet' },
                  })
                " -->
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.internet_facing_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.internet_facing_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">
                  Internet Facing
                </p>
              </div>
              <!-- </b-card> -->
            </b-col>
            <b-col>
              <!-- <b-card class="border-danger rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { grouptype: 'internal', group_id: groupid },
                  })
                "
              >
                <!-- @click="
                  $router.push({
                    path: '/assets/',
                    query: { asset_type: 'internal' },
                  })
                " -->
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.internal_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.internal_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Internal</p>
              </div>
              <!-- </b-card> -->
            </b-col>
                     <b-col>
              <!-- <b-card class="border-danger rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: {  tabIndex: 1 },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.unmanaged_asset_count != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.unmanaged_asset_count)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Unmanaged</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="border-primary rounded">
      <h4 class="text-primary text-left">Asset Category</h4>
      <b-row>
        <b-col lg="12">
          <b-row>
            <b-col>
              <!-- <b-card class="border-warning rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { assettype: 2, group_id: groupid },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.web_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.web_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Web</p>
              </div>
              <!-- </b-card> -->
            </b-col>
            <b-col>
              <!-- <b-card class="border-warning rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { assettype: 1, group_id: groupid },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.network_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.network_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Network</p>
              </div>
              <!-- </b-card> -->
            </b-col>
            <b-col>
              <!-- <b-card class="border-warning rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { assettype: 4, group_id: groupid },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.mobile_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.mobile_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">Mobile</p>
              </div>
              <!-- </b-card> -->
            </b-col>
            <b-col>
              <!-- <b-card class="border-warning rounded text-center mb-0"> -->
              <div
                class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets',
                    query: { assettype: 3, group_id: groupid },
                  })
                "
              >
                <span class="font-weight-bold">
                  <b-avatar
                    v-if="assetCount.api_assets != 0"
                    size="md"
                    variant="light-primary"
                    :text="JSON.stringify(assetCount.api_assets)"
                  />
                  <b-avatar v-else size="md" variant="light-primary" text="0" />
                </span>
                <p class="mb-0 font-weight-bold text-primary">API</p>
              </div>
              <!-- </b-card> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  props: {
    assetCount: {
      type: Object,
      required: true,
    },
    groupid: {
      type: Number,
      required: true,
    },
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },

  data() {
    return {};
  },
  methods: {
    gotoaddAsset() {
      this.$router.push({ path: "/assets/add", query: { isAddAsset: true } });
    },
  },
};
</script>
<style scoped>
.pad-0 {
  padding: 0px !important;
  margin: 0px !important;
}
.bg-blue-grad {
  /* background: linear-gradient(to bottom right,#825cf9,#8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
}
</style>