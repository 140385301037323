<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col
          ><b-card
            class="border-primary rounded text-center cursor-pointer custom-card bg-light-primary"
            @click="$router.push('/assets/')"
          >
            <span
              class="font-weight-bold"
              v-if="generalCount.total_assets != 0"
            >
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.total_assets)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">
              Total Assets
            </p></b-card
          >
        </b-col>
        <b-col
          ><b-card
            class="border-primary rounded text-center cursor-pointer custom-card bg-light-primary"
            @click="
              $router.push({
                path: '/assets/vulnerabilities',
                query: { status: [0, 3, 5], tabIndex: 1 },
              })
            "
          >
            <span class="font-weight-bold" v-if="generalCount.open_vulns != 0">
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.open_vulns)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">Open Vuln</p>
          </b-card>
        </b-col>
        <b-col
          ><b-card
            class="border-primary rounded text-center cursor-pointer custom-card bg-light-primary"
            @click="$router.push('/task')"
          >
            <span class="font-weight-bold" v-if="generalCount.open_tasks != 0">
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.open_tasks)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">Open Tasks</p>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <b-row>
        <b-col
          ><b-card
            class="border-primary rounded text-center cursor-pointer custom-card bg-light-primary"
            @click="$router.push('/scans/')"
          >
            <span class="font-weight-bold" v-if="generalCount.total_scans != 0">
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.total_scans)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">Total Scans</p>
          </b-card>
        </b-col>
        <b-col
          ><b-card
            class="border-primary rounded text-center bg-light-primary custom-card bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/assets/vulnerabilities',
                query: {
                  name: 'Insecure port',
                  status: [0, 3, 5],
                  tabIndex: 1,
                },
              })
            "
          >
            <span
              class="font-weight-bold"
              v-if="generalCount.insecure_ports != 0"
            >
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.insecure_ports)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">Insecure Ports</p>
          </b-card>
        </b-col>
        <!-- @click="handleButtonClick" -->
        <b-col v-b-tooltip.hover.v-info title="Breached - Not Addressed"
          ><b-card
            class="border-primary rounded text-center cursor-pointer custom-card bg-light-primary"
            @click="
              $router.push({
                path: '/Discovery',
                query: { tabIndex: 2, breached: 1 },
              })
            "
          >
            <span
              class="font-weight-bold"
              v-if="generalCount.breached_emails != 0"
            >
              <b-avatar
                size="md"
                variant="light-primary"
                :text="JSON.stringify(generalCount.breached_emails)"
            /></span>
            <span class="font-weight-bold" v-else>
              <b-avatar size="md" variant="light-primary" text="0"
            /></span>
            <p class="mb-0 font-weight-bold text-primary">Breached Emails</p>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    generalCount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  // watch: {
  //   org_id() {
  //     this.load();
  //   },
  // },
  methods: {
    handleButtonClick() {
      this.$emit("scrollToSection");
    },
  },
};
</script>
<style scoped>
.bg-blue-grad {
  background: linear-gradient(to bottom right, #1e1e1e, #0e1945);
  /* background-color: #1e1e1e; */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
</style>