<template>
  <b-card no-body style="height: 422px !important;">
    <b-card-header class="pb-0">
      <b-card-title class="" style="font-size: 14px"
        >Exposed Assets Trend</b-card-title
      >
    </b-card-header>
    <b-card-body>
      <b-row class="" >
        <b-col class="">
          <span class="text-start">
            <span class="text-muted">Count</span>
          </span>
          <e-charts
            class="w-100"
            ref="line"
            autoresize
            :options="chartOptionsLine"
            theme="theme-color"
            auto-resize
          />
          <!-- <div class="text-center text-muted">
            <span>Time Period</span>
          </div> -->
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
} from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import theme from "../theme.json";
ECharts.registerTheme("theme-color", theme);
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    ECharts,
  },
  props: {
    exposedAsset: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptionsLine: {
        title: {
          text: "Exposed Assets",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          title: {
            text: "Time Period",
          },
          type: "category", // Change to category type
          boundaryGap: false,
          data: [],
          splitLine: {},
        },
        yAxis: {
          type: "value", // Change to value type
          title: {
            text: "Count",
          },
          splitLine: {},
        },
        series: [
          {
            name: "Exposed Assets",
            type: "line",
            data: [], // Placeholder for asset counts
            color: ["#127ac2"],
          },
        ],
      },
    };
  },
  mounted() {
    this.updateChart();
  },
  watch: {
    exposedAsset: {
      handler: function () {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    updateChart() {
      const data = this.exposedAsset;
      const formattedDates = data.map((entry) =>
        moment(entry.date).format("DD-MMM-YY")
      ); // Format dates using Moment.js
      const assetCounts = data.map((entry) => entry.asset_count); // Extract asset counts

      this.chartOptionsLine.xAxis.data = formattedDates; // Use formatted dates
      this.chartOptionsLine.series.forEach((series) => {
        series.data = assetCounts;
      });
    },
    // updateChart() {
    //   const data = this.exposedAsset;
    //   const dates = data.map((entry) => entry.date); // Extract dates
    //   const assetCounts = data.map((entry) => entry.asset_count); // Extract asset counts

    //   this.chartOptionsLine.xAxis.data = dates; // Reverse dates for proper display
    //   this.chartOptionsLine.series.forEach((series, index) => {
    //     series.data = assetCounts;
    //   });
    // },
  },
};
</script>

<style scoped>
.box {
  float: left;
  height: 10px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid rgb(255, 255, 255);
  clear: both;
}
.open {
  background-color: #ff9149;
}
.new {
  background-color: #127ac2;
}
.closed {
  background-color: #28d094;
}
.p-1px {
  padding-left: 2px;
  padding-right: 2px;
}
.m-2px {
  margin-left: 2px;
  margin-right: 2px;
}
</style>