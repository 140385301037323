<template>
  <b-card no-body>
    <!-- {{ org_id }}
    {{ overallVuln }} -->

    <b-card-header class="pb-0">
      <b-card-title>Vulnerabilities</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col>
          <e-charts
            class="w-100"
            ref="bar"
            autoresize
            :options="chartOptionsBar"
            theme="theme-color"
            auto-resize
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
// import { $themeColors } from "@themeConfig";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,

    ECharts,
  },
  props: {
    overallVuln: {
      type: Object,
      required: true,
    },
    org_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      chartOptionsBar: {
        xAxis: {
          data: ["Critical", "High", "Medium", "Low"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: [
              this.overallVuln.critical_vulns,
              this.overallVuln.high_vulns,
              this.overallVuln.medium_vulns,
              this.overallVuln.low_vulns,
            ],
            itemStyle: {
              color: "#ff9f43",
            },
          },
        ],
      },
    };
  },
  watch: {
    overallVuln: function (newVal, oldVal) {
      // update chart data with new overallVuln prop value
      this.chartOptionsBar.series[0].data = [
        newVal.critical_vulns,
        newVal.high_vulns,
        newVal.medium_vulns,
        newVal.low_vulns,
      ];
    },
  },

  //   computed: {
  //     // a computed getter
  //     series: function () {
  //       var c = Math.round(
  //         ((this.internalrisk.risk_profile.critical_risk_assets +
  //           this.internalrisk.risk_profile.high_risk_assets +
  //           this.internalrisk.risk_profile.medium_risk_assets ) /
  //           this.internalrisk.total_assets) *
  //           100
  //       );
  //       console.log("c", c);
  //       return [c];
  //     },
  //   },
};
</script>
