<template>
  <b-card class="border-primary rounded">
    <div class="pb-1">
      <h4 class="text-primary">Scans Overview</h4>
    </div>

    <b-row>
      <b-col lg="12">
        <b-row class="justify-content-center">
          <b-col>
            <!-- <b-card class="border-primary rounded text-center mb-0"> -->
            <div
              class="border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/scans',
                  query: { status: 2 },
                })
              "
            >
              <span class="font-weight-bold">
                <b-avatar
                  v-if="scanCount.completed_scans"
                  size="md"
                  variant="light-primary"
                  :text="JSON.stringify(scanCount.completed_scans)"
                />
                <b-avatar v-else size="md" variant="light-primary" text="0" />
              </span>
              <p class="mb-0 font-weight-bold text-primary">Completed Scans</p>
            </div>
            <!-- </b-card> -->
          </b-col>
          <b-col>
            <!-- <b-card class="border-primary rounded text-center mb-0"> -->
            <div
              class="border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/scans',
                  query: {status: [0, 1]},
                })
              "
            >
              <span class="font-weight-bold">
                <b-avatar
                  v-if="scanCount.active_scan"
                  size="md"
                  variant="light-primary"
                  :text="JSON.stringify(scanCount.active_scan)"
                />
                <b-avatar v-else size="md" variant="light-primary" text="0" />
              </span>
              <p class="mb-0 font-weight-bold text-primary">Active Scans</p>
            </div>
            <!-- </b-card
            > -->
          </b-col>
          <b-col>
            <!-- <b-card class="border-primary rounded text-center mb-0"> query: { status: 0 }, -->
            <div
              class="border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/schedules',
                })
              "
            >
              <span class="font-weight-bold">
                <b-avatar
                  v-if="scanCount.active_schedules"
                  size="md"
                  variant="light-primary"
                  :text="JSON.stringify(scanCount.active_schedules)"
                />
                <b-avatar v-else size="md" variant="light-primary" text="0" />
              </span>
              <p class="mb-0 font-weight-bold text-primary">Active Schedules</p>
            </div>
            <!-- </b-card
            > -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    scanCount: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
};
</script>
<style scoped>
.bg-blue-grad {
  /* background: linear-gradient(to bottom right, #825cf9, #8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
</style>