<template>
  <b-card no-body>
    <b-card-header>
      <!-- {{ values }} -->
      <b-card-title class="text-primary">Vulnerability Summary</b-card-title>
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="values"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
      customersPie: {
        series: [690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["Low", "Medium", "High", "Critical"],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.success,
            $themeColors.warning,
            $themeColors.danger,
            '#a020f0',
            // '#991D28',
          ],
        },
      },
    };
  },
  // methods: {
  //   onOrgChange(e) {
  //     this.chartOptions = {
  //       theme: {
  //         palette: e.target.value,
  //       },
  //     };
      
  //   },
  // },
};
</script>
