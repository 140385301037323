<template>
  <div>
    <b-row>
      <b-col lg="3">
        <b-card-code
          class="card card-congratulations"
          @click="$router.push('/assets/add')"
          text-variant="center"
          style="cursor: pointer"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <h5 class="text-white">Assets</h5>
        </b-card-code>
      </b-col>
      <b-col lg="3">
        <b-card-code
          class="card card-congratulations"
          @click="$router.push('/assets/group')"
          text-variant="center"
          style="cursor: pointer"
        >
          <feather-icon icon="BoxIcon" class="mr-50" />
          <h5 class="text-white">Group</h5>
        </b-card-code>
      </b-col>
      <b-col lg="3">
        <b-card-code
          class="card card-congratulations"
          @click="$router.push('/task/')"
          text-variant="center"
          style="cursor: pointer"
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <h5 class="text-white">Tasks</h5>
        </b-card-code>
      </b-col>
      <b-col lg="3">
        <b-card-code
          class="card card-congratulations"
          @click="$router.push('/users/')"
          text-variant="center"
          style="cursor: pointer"
        >
          <feather-icon icon="UserIcon" class="mr-50" />
          <h5 class="text-white">Users</h5>
        </b-card-code>
      </b-col>
    </b-row>
  </div>

  <!-- <div class="demo-inline-spacing">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      @click="$router.push('/assets/add')"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Assets</span>
    </b-button>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="$router.push('/assets/vulnerabilities')"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Vulnerability</span>
    </b-button>

    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="primary"
      @click="$router.push('/task/')"
    >
      <feather-icon
        icon="EditIcon"
        class="mr-50"
      />
      <span class="align-middle">Tasks</span>
    </b-button>

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="$router.push('/users/')"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Add User</span>
    </b-button>

  </div> -->
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BButton, BRow, BCol, BCard, BCardHeader } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardCode,
  },
  directives: {
    Ripple,
  },
};
</script>