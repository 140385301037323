var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('h4',{staticClass:"mb-0 text-info"},[_vm._v("Tasks Summary")])]),_c('vue-apex-charts',{attrs:{"type":"radialBar","height":"245","options":_vm.goalOverviewRadialBar.chartOptions,"series":_vm.tasks_completion}}),_c('b-row',{staticClass:"text-center mx-0"},[_c('b-col',{staticClass:"border-top border-right d-flex align-items-between flex-column py-1",attrs:{"cols":"3"}},[_c('b-card-text',{staticClass:"text-muted mb-0"},[_vm._v(" Total ")]),_c('h3',{staticClass:"font-weight-bolder mb-50 cursor-pointer",on:{"click":function($event){return _vm.$router.push('/task')}}},[_vm._v(" "+_vm._s(_vm.stats.tasks.total_tasks)+" ")])],1),_c('b-col',{staticClass:"border-top border-right d-flex align-items-between flex-column py-1",attrs:{"cols":"3"}},[_c('b-card-text',{staticClass:"text-muted mb-0"},[_vm._v(" Open ")]),_c('h3',{staticClass:"font-weight-bolder mb-50 cursor-pointer",on:{"click":function($event){return _vm.$router.push('/task')}}},[_vm._v(" "+_vm._s(_vm.stats.tasks.open_tasks)+" ")])],1),_c('b-col',{staticClass:"border-top border-right d-flex align-items-between flex-column py-1",attrs:{"cols":"3"}},[_c('b-card-text',{staticClass:"text-muted mb-0"},[_vm._v(" Completed ")]),_c('h3',{staticClass:"font-weight-bolder mb-50 cursor-pointer",on:{"click":function($event){return _vm.$router.push({
            path: '/task',
            query: {
              status: 2,
            },
          })}}},[_vm._v(" "+_vm._s(_vm.stats.tasks.completed_tasks)+" ")])],1),_c('b-col',{staticClass:"border-top d-flex align-items-between flex-column py-1",attrs:{"cols":"3"}},[_c('b-card-text',{staticClass:"text-muted mb-0"},[_vm._v(" In Progress ")]),_c('h3',{staticClass:"font-weight-bolder mb-0 cursor-pointer",on:{"click":function($event){return _vm.$router.push({
            path: '/task',
            query: {
              status: 1,
            },
          })}}},[_vm._v(" "+_vm._s(_vm.stats.tasks.in_progress_tasks)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }