<template>
  <b-row>
    <b-col lg="12">
      <!-- {{groupid}} -->
      <!-- style="background: rgba(255, 159, 67, 0.12)" -->
      <b-card class="border-primary rounded">
        <div class="d-flex align-items-center">
          <span class="text-primary h3 mr-1">
            <b-avatar
              v-if="
                vulnCount.vulnerability_count.open_vulnerabilities
                  .total_open_vulns
              "
              size="md"
              variant="light-primary"
              :text="
                JSON.stringify(
                  vulnCount.vulnerability_count.open_vulnerabilities
                    .total_open_vulns
                )
              "
            />
            <b-avatar v-else size="md" variant="light-primary" text="0" />
          </span>
          <h4 class="text-primary text-center">Open Vulnerabilities</h4>
        </div>
        <b-row>
          <b-col
            ><div
              class="border-purple rounded text-center bg-blue-grad bg-purple cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/assets/vulnerabilities',
                  query: { severity: 'critical', status: [0, 3, 5] ,group_id: groupid ,tabIndex: 1},
                })
              "
            >
              <span class="h5 text-purple">
                {{
                  vulnCount.vulnerability_count.open_vulnerabilities
                    .critical_open_vulns
                }}</span
              ><br /><span class="text-purple h6">Critical</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-danger rounded text-center bg-light-danger bg-blue-grad cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/assets/vulnerabilities',
                  query: { severity: 'high', status: [0, 3, 5],group_id: groupid ,tabIndex: 1 },
                })
              "
            >
              <span class="h5 text-danger">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .high_open_vulns
              }}</span
              ><br /><span class="text-danger h6">High</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-warning rounded text-center bg-blue-grad bg-light-warning cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/assets/vulnerabilities',
                  query: { severity: 'medium', status: [0, 3, 5],group_id: groupid ,tabIndex: 1 },
                })
              "
            >
              <span class="h5 text-warning">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .medium_open_vulns
              }}</span
              ><br /><span class="text-warning h6">Medium</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-success rounded text-center bg-blue-grad bg-light-success cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/assets/vulnerabilities',
                  query: { severity: 'low', status: [0, 3, 5],group_id: groupid ,tabIndex: 1 },
                })
              "
            >
              <span class="h5 text-success">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .low_open_vulns
              }}</span
              ><br /><span class="text-success h6">Low</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-info rounded text-center bg-blue-grad bg-light-info cursor-pointer custom-card"
              @click="
                $router.push({
                  path: '/assets/vulnerabilities',
                  query: { severity: 'info', status: [0, 3, 5],group_id: groupid ,tabIndex: 1 },
                })
              "
            >
              <span class="h5 text-info">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .info_open_vulns
              }}</span
              ><br /><span class="text-info h6">Info</span>
            </div></b-col
          >
        </b-row>
      </b-card>
    </b-col>
    <b-col lg="12">
      <!-- style="background: #28c76f66" -->
      <b-card class="border-primary rounded">
        <div class="d-flex align-items-center">
          <span class="text-primary h3 mr-1">
            <b-avatar
              v-if="
                vulnCount.vulnerability_count.new_vulnerabilities
                  .total_new_vulns
              "
              size="md"
              variant="light-primary"
              :text="
                JSON.stringify(
                  vulnCount.vulnerability_count.new_vulnerabilities
                    .total_new_vulns
                )
              "
            />
            <b-avatar v-else size="md" variant="light-primary" text="0" />
          </span>
          <h4 class="text-primary text-center">
            New Vulnerabilities(Last 30 days)
          </h4>
        </div>
        <b-row>
          <b-col
            ><div
              class="border-purple rounded text-center bg-blue-grad bg-purple cursor-pointer"
              @click="navigateToPage('critical')"
            >
              <!-- cursor-pointer custom-card -->
              <span class="h5 text-purple">
                {{
                  vulnCount.vulnerability_count.new_vulnerabilities
                    .critical_new_vulns
                }}</span
              ><br /><span class="text-purple h6">Critical</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-danger rounded text-center bg-blue-grad bg-light-danger cursor-pointer"
              @click="navigateToPage('high')"
            >
              <span class="h5 text-danger">{{
                vulnCount.vulnerability_count.new_vulnerabilities.high_new_vulns
              }}</span
              ><br /><span class="text-danger h6">High</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-warning rounded text-center bg-blue-grad bg-light-warning cursor-pointer"
              @click="navigateToPage('medium')"
            >
              <span class="h5 text-warning">{{
                vulnCount.vulnerability_count.new_vulnerabilities
                  .medium_new_vulns
              }}</span
              ><br /><span class="text-warning h6">Medium</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-success rounded text-center bg-blue-grad bg-light-success cursor-pointer"
            @click="navigateToPage('low')"
            >
              <span class="h5 text-success">{{
                vulnCount.vulnerability_count.new_vulnerabilities.low_new_vulns
              }}</span
              ><br /><span class="text-success h6">Low</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-info rounded text-center bg-blue-grad bg-light-info cursor-pointer"
            @click="navigateToPage('info')"
            >
              <span class="h5 text-info">{{
                vulnCount.vulnerability_count.new_vulnerabilities.info_new_vulns
              }}</span
              ><br /><span class="text-info h6">Info</span>
            </div></b-col
          >
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    vulnCount: {
      type: Object,
      required: true,
    },
    groupid: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    navigateToPage(severity) {
      const fromDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");
      let severityParam = ""; // Initialize severity parameter
      

      if (severity === "critical") {
        severityParam = "critical";
      } else if (severity === "high") {
        severityParam = "high";
      } else if (severity === "medium") {
        severityParam = "medium";
      } else if (severity === "low") {
        severityParam = "low";
      } else if (severity === "info") {
        severityParam = "info";
      }

      this.$router.push({
        path: "/assets/vulnerabilities",
        query: {
          severity: severityParam,
          fromDate: fromDate,
          endDate: endDate,
          group_id: this.groupid,
          tabIndex: 1,
        },
      });
    },
  },
};
</script>
<style scoped>
.bg-blue-grad {
  /* background: linear-gradient(to bottom right,#825cf9,#8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
}

.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>