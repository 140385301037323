<template>
  <b-card no-header>
    <div class="row align-items-center">
      <div class="col">
        <b-card-title class="text-primary">Domain Discovered</b-card-title>
      </div>
      <div class="col-8 d-flex justify-content-end mb-1">
        <b-form-input
          class="mr-1 w-50"
          id="domainname"
          v-model="domain_filter"
          name="name"
          placeholder="Search with Domain"
        />
        <b-form-input
          class="mr-1 w-50"
          id="ipname"
          v-model="ip_filter"
          name="name"
          placeholder="Search with IP"
        />
        <button
          type="button"
          class="btn btn-secondary btn-sm mr-1"
          @click="resetFn()"
        >
          Reset
        </button>
        <button type="button" class="btn btn-primary btn-sm" @click="load()">
          Search
        </button>
      </div>
    </div>

    <div>
      <b-table
        v-if="items.length"
        responsive
        id="blTable"
        :fields="fields"
        :items="items"
      >
        <template #cell(asset)="data">
          {{ data.item.asset }} <br />
          <div v-if="data.item.is_newly_founded && data.item.domain != null">
            <b-button
              @click="addToAssets(data.item.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </b-button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.domain_added_to_asset && data.item.domain != null
            "
          >
            <b-button
              @click="addToAssets(data.item.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </b-button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.domain_added_to_asset && data.item.domain != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.domain_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.domain_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
        <template #cell(ip_address)="data">
          {{ data.item.ip_address }} <br />
          <div
            v-if="data.item.is_newly_founded && data.item.ip_address != null"
            :disabled="isDisableIP"
          >
            <b-button
              @click="addToAssets(data.item.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              v-if="data.item.is_newly_founded && data.item.ip_address != null"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </b-button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.ip_added_to_asset && data.item.ip_address != null
            "
          >
            <b-button
              @click="addToAssets(data.item.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </b-button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.ip_added_to_asset && data.item.ip_address != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.ip_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.ip_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="EmailIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Domain Discovered</h3>
          <p>
            Navigate to detailed insights ,
            <span
              class="font-weight-bold text-success cursor-pointer"
              @click="
                $router.push({
                  path: '/Discovery',
                  query: { tabIndex: 1 },
                })
              "
              >Click here</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end py-1" v-if="items.length > 9">
      <router-link class="text-white" :to="'/dashboard/domain-list'">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          style="position: relative"
        >
          See More >>
        </button>
      </router-link>
    </div>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BCardTitle,
  BCardHeader,
  BSpinner,
  BFormInput,
  // BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BCardTitle,
    BCardHeader,
    BSpinner,
    BFormInput,
    vSelect,
    // BButton,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        // { key: "asset", label: "Name" },
        { key: "domain", label: "Domain" },
        { key: "asset", label: "Domain Discovered" },
        { key: "ip_address", label: "IP" },
      ],
      items: [],
      isLoading: false,
      isDisable: false,
      isLoadingIP: false,
      isDisableIP: false,
      type: "",
      currentIndexDomain: Number,
      currentIndexIP: Number,
      domain_filter: "",
      ip_filter: "",
    };
  },
  created: function () {
    if (this.org_id != null) {
      this.load();
    }
  },
  methods: {
    load: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v1/domains-discovered?query=true";

      if (this.domain_filter != "") {
        url = url + "&domain=" + this.domain_filter;
      }
      if (this.ip_filter != "") {
        url = url + "&ip_address=" + this.ip_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
    resetFn() {
      this.domain_filter = "";
      this.ip_filter = "";
      this.load();
    },
    addToAssets: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }

      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-to-asset/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },

    addAndScan: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-asset-and-scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.loadAssets();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>