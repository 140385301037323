var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"border-primary rounded text-center cursor-pointer custom-card bg-light-primary",on:{"click":function($event){return _vm.$router.push('/assets/')}}},[(_vm.generalCount.total_assets != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.total_assets)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v(" Total Assets ")])])],1),_c('b-col',[_c('b-card',{staticClass:"border-primary rounded text-center cursor-pointer custom-card bg-light-primary",on:{"click":function($event){return _vm.$router.push({
              path: '/assets/vulnerabilities',
              query: { status: [0, 3, 5], tabIndex: 1 },
            })}}},[(_vm.generalCount.open_vulns != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.open_vulns)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Open Vuln")])])],1),_c('b-col',[_c('b-card',{staticClass:"border-primary rounded text-center cursor-pointer custom-card bg-light-primary",on:{"click":function($event){return _vm.$router.push('/task')}}},[(_vm.generalCount.open_tasks != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.open_tasks)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Open Tasks")])])],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"border-primary rounded text-center cursor-pointer custom-card bg-light-primary",on:{"click":function($event){return _vm.$router.push('/scans/')}}},[(_vm.generalCount.total_scans != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.total_scans)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Total Scans")])])],1),_c('b-col',[_c('b-card',{staticClass:"border-primary rounded text-center bg-light-primary custom-card bg-light-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: '/assets/vulnerabilities',
              query: {
                name: 'Insecure port',
                status: [0, 3, 5],
                tabIndex: 1,
              },
            })}}},[(_vm.generalCount.insecure_ports != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.insecure_ports)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Insecure Ports")])])],1),_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-info",modifiers:{"hover":true,"v-info":true}}],attrs:{"title":"Breached - Not Addressed"}},[_c('b-card',{staticClass:"border-primary rounded text-center cursor-pointer custom-card bg-light-primary",on:{"click":function($event){return _vm.$router.push({
              path: '/Discovery',
              query: { tabIndex: 2, breached: 1 },
            })}}},[(_vm.generalCount.breached_emails != 0)?_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.generalCount.breached_emails)}})],1):_c('span',{staticClass:"font-weight-bold"},[_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Breached Emails")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }