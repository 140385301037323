<template>
  <div>
    <!-- <b-card no-body> -->
    <b-card-header>
      <b-card-title style="font-size: 14px">Domain Distribution</b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        v-if="!values.every((value) => value === 0)"
        type="donut"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="values"
      />
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2 mt-4"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
    <!-- </b-card> -->
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    keys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
      customersPie: {
        series: [690, 258],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: this.keys,
          dataLabels: {
            enabled: true,
          },
          legend: { show: true, position: "bottom" },
          stroke: {
            width: 4,
          },
          colors: [
           "#0d98ba",
            "#097782",
            "#8bb1b5",
            "#034f84",
            "#39a09e",
          ],
        },
      },
    };
  },
  computed: {
    // Calculate labels with counts
    labeledValues() {
      return this.values.map(
        (value, index) =>
          `${this.customersPie.chartOptions.labels[index]} - ${value}`
      );
    },
  },
  watch: {
    values: {
      handler(newVal) {
        this.customersPie.chartOptions.labels = this.labeledValues;
      },
      immediate: true,
    },
  },
};
</script>
