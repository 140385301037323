<template>
  <b-card no-body>
    <b-card-header class="pb-1">
      <b-card-title class="text-primary"
        >Top Severe Vulnerabilities</b-card-title
      >
    </b-card-header>
    <b-table
      responsive
      id="orgTable"
      :fields="fields"
      :items="top_severe_vulnerabilities"
      hover
      style="text-transform: capitalize"
    >
      <template #cell(name)="data">
        <a class="text-truncate" @click="redirectToSummary(data.item.name)">
          <span v-html="breakTextIntoLines(data.item.name)"></span>
        </a>
        <!-- <a
          v-if="data.item.name.length > 30"
          @click="toggleExpand(data.index)"
          size="sm"
          variant="outline-primary"
        >
          <span style="font-size: 12px !important">{{
            isExpanded(data.index) ? " show less" : "..."
          }}</span>
        </a> -->
      </template>
      <template #cell(severity)="data">
        <b-badge
          style="background-color: #a020f0"
          v-if="data.item.severity == 'critical'"
          >Critical</b-badge
        >
        <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
          >High</b-badge
        >
        <b-badge variant="warning" v-else-if="data.item.severity == 'medium'"
          >Medium</b-badge
        >
        <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
          >Low</b-badge
        >
        <b-badge variant="success" v-else-if="data.item.severity == 'info'"
          >Info</b-badge
        >
        <b-badge variant="secondary" v-else>Unknown</b-badge>
      </template>
      <template #cell(count)="data">
        <div class="d-flex justify-content-center">
          <b-avatar
            size="sm"
            variant="secondary"
            :text="JSON.stringify(data.item.count)"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BBadge,
  BAvatar,
  BCardTitle,
  BCardHeader,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BBadge,
    BAvatar,
    BCardTitle,
    BCardHeader,
  },
  props: {
    top_severe_vulnerabilities: {
      type: Array,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      items: [],
      fields: [
        { key: "name", label: "Name" },
        { key: "severity", label: "Severity" },
        { key: "count", label: "Count" },
      ],
      expandedItems: [],
    };
  },
  // created: function () {
  //   if (!this.$store.state.app.user.is_staff) {
  //     this.load();
  //   }
  // },
  // mounted() {
  //   if (this.org_id != null) {
  //     this.load();
  //   }
  // },
  // watch: {
  //   org_id() {
  //     this.load();
  //   },
  // },
  methods: {
    // toggleExpand(index) {
    //   const currentIndex = this.expandedItems.indexOf(index);
    //   if (currentIndex === -1) {
    //     this.expandedItems.push(index);
    //   } else {
    //     this.expandedItems.splice(currentIndex, 1);
    //   }
    // },
    // isExpanded(itemId) {
    //   return this.expandedItems.includes(itemId);
    // },
    // truncateName(name) {
    //   if (name.length > 30) {
    //     return name.slice(0, 30);
    //   }
    //   return name;
    // },
    breakTextIntoLines(text) {
      const maxCharacters = 60;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    redirectToSummary(name, severity) {
      this.$router.push({
        path: "/assets/vulnerabilities/summary",
        query: { name, severity: severity }, // Pass the "name" as a query parameter
      });
    },
  },
};
</script>