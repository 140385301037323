import { render, staticRenderFns } from "./vuln_by_age.vue?vue&type=template&id=e1f0d064&scoped=true"
import script from "./vuln_by_age.vue?vue&type=script&lang=js"
export * from "./vuln_by_age.vue?vue&type=script&lang=js"
import style0 from "./vuln_by_age.vue?vue&type=style&index=0&id=e1f0d064&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1f0d064",
  null
  
)

export default component.exports