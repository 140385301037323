<template>
  <b-card-code title="SSL Score">
    <!-- {{ items }} -->
    <b-table responsive id="blTable" hover :fields="fields" :items="items">
       <template #cell(asset)="data">
        <router-link :to="'/assets/scan/' + data.item.asset_id">{{
          data.item.asset
        }}</router-link>
      </template>
      <template #cell(name)="data">
        <div v-for="name in data.item.grade" :key="name">
          <a class="text-primary h5">{{ name.ipAddress }}</a>
          <br />
          <span>{{ name.statusMessage }}</span>
        </div>
      </template>
      <!-- <template #cell(testtime)="data">
        <p class="mb-0">
          <span>{{ new Date(data.item.testTime).toLocaleString() }}</span>
        </p>
      </template> -->

      <template #cell(grade)="data">
        <div v-for="grad in data.item.grade" :key="grad">
          <h4 v-if="grad.grade === 'A+' || 'A'" class="text-success">
            <b>{{ grad.grade }}</b>
          </h4>
          <h4
            v-else-if="grad.grade === 'B' || 'C' || 'D' || 'E' || 'F'"
            class="text-warning"
          >
            <b>{{ grad.grade }}</b>
          </h4>
          <h4 v-else-if="grad.grade === 'A-' || 'T' || 'M'" class="text-danger">
            <b>{{ grad.grade }}</b>
          </h4>
          <h4 v-else>{{ grad.grade }}</h4>
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
  },
    props: {
    org_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        // { key: "", label: "#" },
        { key: "asset", label: "Asset" },
        { key: "name", label: "Server" },
        // { key: "testtime", label: "TEST TIME" },
        { key: "grade", label: "GRADE" },
      ],
      items: [],
    };
  },
  created: function () {
    if (this.org_id != null) {
      this.load();
    }
  },
  //   filters: {
  //   formattime: function (ms) {
  //     let seconds = (ms / 1000).toFixed(1);
  //     let minutes = (ms / (1000 * 60)).toFixed(1);
  //     let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  //     let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  //     if (seconds < 60) return seconds + " Sec";
  //     else if (minutes < 60) return minutes + " Min";
  //     else if (hours < 24) return hours + " Hrs";
  //     else return days + " Days";
  //   },
  // },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "dashboard/v1/ssl-score?org_id=" +
         this.org_id,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>