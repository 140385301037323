var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border-primary rounded"},[_c('div',{staticClass:"pb-1"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Scans Overview")])]),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                path: '/scans',
                query: { status: 2 },
              })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.scanCount.completed_scans)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.scanCount.completed_scans)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Completed Scans")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                path: '/scans',
                query: {status: [0, 1]},
              })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.scanCount.active_scan)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.scanCount.active_scan)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Active Scans")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center mb-0 bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                path: '/schedules',
              })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.scanCount.active_schedules)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.scanCount.active_schedules)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Active Schedules")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }