<template>
  <b-card-code title="IP Score">
    <!-- {{ items }} -->
    <b-table responsive id="blTable" :fields="fields" :items="items">
      <template #cell(asset)="data">
        <router-link :to="'/assets/scan/' + data.item.asset_id">{{
          data.item.asset
        }}</router-link>
      </template>
       <template #cell(fraud_score)="data">
        <b-badge variant="light-primary">{{
          data.item.fraud_score
        }}</b-badge>
      </template>
      <template #cell(country_code)="data">
        <div class="d-flex justify-content-center">
        <b-badge variant="light-success">{{
          data.item.country_code
        }}</b-badge>
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "asset", label: "Asset" },
        { key: "fraud_score", label: "Score" },
        // { key: "abuse_velocity", label: "Advice" },
        { key: "country_code", label: "Country Code" },
      ],
      items: [],
    };
  },
  created: function () {
    if (this.org_id != null) {
      this.load();
    }
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "dashboard/v1/ip-score?org_id=" +
          this.org_id,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>