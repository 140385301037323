<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-primary">Top Exploited Assets</b-card-title>
    </b-card-header>
    <b-table
      responsive
      id="orgTable"
      :fields="fields"
      :items="assets_most_exploittable"
      hover
    >
      <template #cell(1)="data">
        <router-link :to="'/assets/scan/' + data.item[0]">{{
          data.item[1]
        }}</router-link>
      </template>
      <template #cell(3)="data">
        <div class="d-flex justify-content-center">
          <b-avatar
            size="sm"
            variant="secondary"
            :text="JSON.stringify(data.item[3])"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BCard, BTable, BAvatar, BCardHeader, BCardTitle } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BAvatar,
    BCardHeader,
    BCardTitle,
  },
  props: {
    assets_most_exploittable: {
      type: Array,
      required: true,
    },
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      // Call orgaization API and return in this format
      items: [],
      fields: [
        { key: "1", label: "Asset" },
        { key: "2", label: "Target" },
        { key: "3", label: "Count" },
      ],
    };
  },
  // created: function () {
  //   if (!this.$store.state.app.user.is_staff) {
  //     this.load();
  //   }
  // },
  // mounted() {
  //   if (this.org_id != null) {
  //     this.load();
  //   }
  // },
  // watch: {
  //   org_id() {
  //     this.load();
  //   },
  // },
  methods: {
    // load: function () {
    //   const options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "dashboard/v1/assets-most-exploittable?org_id=" +
    //       this.org_id,
    //   };
    //   this.$http(options).then((res) => {
    //     console.log(res.data, "most exploitable");
    //     this.items = res.data;
    //   });
    // },
  },
};
</script>