<template>
  <b-card no-body>
    <b-card-header class="pb-0">
      <b-card-title class="text-primary">Vulnerability severity by Age</b-card-title>
    </b-card-header>

    <b-card-body>
       <div class="d-flex justify-content-center mt-1">
        <div class="d-flex">
          <span class="d-flex align-items-center p-1px"><div class='box critical  mb-0  m-1px'></div>Critical</span>
          <span class="d-flex align-items-center p-1px"><div class='box high mb-0  m-1px'></div>High</span>
          <span class="d-flex align-items-center p-1px"><div class='box medium mb-0  m-1px'></div>Medium</span>
          <span class="d-flex align-items-center p-1px"><div class='box low mb-0  m-1px'></div>Low</span>
        </div>
      </div>
      <b-row>
        <b-col>
          <e-charts
            class="w-100"
            ref="bar"
            autoresize
            :options="chartOptionsBar"
            theme="theme-color"
            auto-resize
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
// import { $themeColors } from "@themeConfig";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import theme from './theme.json'
ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,

    ECharts,
  },
  props: {
    vuln_by_age: {
      type: Object,
      required: true,
    },
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },

 data() {
  return {
    chartOptionsBar: {
       tooltip: {
          trigger: "axis",
        },
      xAxis: {
        data: ["0-30 days" ,"30-60 days", "60-90 days", ">90 days"],
        // axisLine: {
        //   lineStyle: {
        //     color: "#ffffff",
        //   },
        // },
        // axisLabel: {
        //   textStyle: {
        //     color: "#ffffff",
        //   },
        // },
      },
      yAxis: {
        type: "value",
        // axisLine: {
        //   lineStyle: {
        //     color: "#ffffff",
        //   },
        // },
        // axisLabel: {
        //   textStyle: {
        //     color: "#ffffff",
        //   },
        // },
      },
      series: [
        {
          name: "Critical",
          type: "bar",
          data: Object.values(this.vuln_by_age).map((item) => item.critical_vulns),
          itemStyle: {
            color: "#a020f0",
          },
        },
        {
          name: "High",
          type: "bar",
          data: Object.values(this.vuln_by_age).map((item) => item.high_vulns),
          itemStyle: {
            color: "#ea5455 ",
          },
        },
        {
          name: "Medium",
          type: "bar",
          data: Object.values(this.vuln_by_age).map((item) => item.medium_vulns),
          itemStyle: {
            color: "#ff9f43",
          },
        },
        {
          name: "Low",
          type: "bar",
          data: Object.values(this.vuln_by_age).map((item) => item.low_vulns),
          itemStyle: {
            color: "#28c76f",
          },
        },
      ],
    },
  };
},
watch: {
  vuln_by_age: {
    handler: function() {
    //   this.chartOptionsBar.xAxis.data = Object.keys(this.vuln_by_age);
      this.chartOptionsBar.series[0].data = Object.values(this.vuln_by_age).map((item) => item.critical_vulns);
      this.chartOptionsBar.series[1].data = Object.values(this.vuln_by_age).map((item) => item.high_vulns);
      this.chartOptionsBar.series[2].data = Object.values(this.vuln_by_age).map((item) => item.medium_vulns);
      this.chartOptionsBar.series[3].data = Object.values(this.vuln_by_age).map((item) => item.low_vulns);
    },
    deep: true
  }
},

// watch: {
//   vuln_by_age: function (newVal, oldVal) {
//     // update chart data with new vuln_by_age prop value
//     this.chartOptionsBar.series[0].data = Object.values(newVal).map((item) => item.critical_vulns);
//     this.chartOptionsBar.series[1].data = Object.values(newVal).map((item) => item.high_vulns);
//     this.chartOptionsBar.series[2].data = Object.values(newVal).map((item) => item.medium_vulns);
//     this.chartOptionsBar.series[3].data = Object.values(newVal).map((item) => item.low_vulns);
//   },
// },
 
};
</script>
<style scoped>
.box {
  float: left;
  height: 10px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid rgb(255, 255, 255);
  clear: both;
}
.critical{  
  background-color:#a020f0;
}
.high{  
  background-color:#ea5455;
}
.medium{  
  background-color:#ff9f43;
}
.low{  
  background-color:#28c76f;
}
.p-1px{
  padding-left: 2px;
  padding-right: 2px;
}
.m-1px{
  margin-left: 2px;
  margin-right:2px;
}
</style>
