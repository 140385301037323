<template>
  <b-card no-body>
    <!-- {{ org_id }} -->
    <!-- {{ scanCreditTrend }}  -->

    <b-card-header class="pb-0">
      <b-card-title class="text-primary">3 Month Trend of Scan Credit Utilization</b-card-title>
    </b-card-header>

    <b-card-body>
      <div class="d-flex justify-content-center mt-1">
        <div class="d-flex">
          <span class="d-flex align-items-center p-1px"
            ><div class="box graphblue mb-0" style="margin-right:3px"></div>
            Credit</span
          >
        </div>
      </div>
      <b-row>
        <b-col>
          <e-charts
            class="w-100"
            ref="bar"
            autoresize
            :options="chartOptionsBar"
            theme="theme-color"
            auto-resize
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
// import { $themeColors } from "@themeConfig";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import theme from "./theme.json";
ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,

    ECharts,
  },
  props: {
    scanCreditTrend: {
      type: Object,
      required: true,
    },
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },

  data() {
    return {
      chartOptionsBar: {
        xAxis: {
          data: [],
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        series: [
          {
            type: "bar",
            data: [
              //   this.scanCreditTrend.February,
              // this.scanCreditTrend.March,
              // this.scanCreditTrend.April,
              // this.scanCreditTrend.May,
            ],
            itemStyle: {
              color: "#7367f0",
            },
            barWidth: "40%",
          },
        ],
      },
    };
  },
    watch: {
    scanCreditTrend: function (newVal, oldVal) {
      // Generate x-axis data and series data dynamically
      const months = Object.keys(newVal);
      const scanData = months.map((month) => newVal[month]);

      months.shift();
      scanData.shift();

      this.chartOptionsBar.xAxis.data = months;
      this.chartOptionsBar.series[0].data = scanData;
    },
  },
  
  // watch: {
  //   scanCreditTrend: function (newVal, oldVal) {
  //     // update chart data with new scanCreditTrend prop value
  //     this.chartOptionsBar.series[0].data = [
  //       // newVal.February,
  //       newVal.March,
  //       newVal.April,
  //       newVal.May,
  //     ];
  //   },
  // },

  //   computed: {
  //     // a computed getter
  //     series: function () {
  //       var c = Math.round(
  //         ((this.internalrisk.risk_profile.critical_risk_assets +
  //           this.internalrisk.risk_profile.high_risk_assets +
  //           this.internalrisk.risk_profile.medium_risk_assets ) /
  //           this.internalrisk.total_assets) *
  //           100
  //       );
  //       console.log("c", c);
  //       return [c];
  //     },
  //   },
};
</script>
<style scoped>
.box {
  float: left;
  height: 10px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid rgb(255, 255, 255);
  clear: both;
}
.graphblue {
  background-color: #7367f0;
}
.p-1px {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
