<template>
  <b-card-code no-body>
    <!-- {{ tab_id }} -->
    <div class="row justify-content-between align-items-center">
      <div class="col">
        <h3 class="ml-2 text-primary">Baseline Deviations</h3>
      </div>
      <div class="col-7 d-flex justify-content-end">
        <b-form-input
          class="mr-1 w-25"
          id="portname"
          v-model="port_filter"
          name="name"
          placeholder="Search with Port"
        />
        <v-select
          class=""
          v-model="multi_asset_filter"
          label="text"
          placeholder="Search Asset"
          :options="multi_asset_options"
          @search="fetchOptions"
          autocomplete
          style="width: 70%"
          :reduce="(multi_asset_filter) => multi_asset_filter.value"
        />
        <b-button class="ml-1" variant="secondary" @click="resetFn()">
          Reset
        </b-button>
        <b-button class="ml-1 mr-2" variant="primary" @click="searchFn()">
          Search
        </b-button>
      </div>
    </div>
    <div class="m-2">
      <table ref="baselineId">
        <tr class="text-Capitalize">
          <th>Asset</th>
          <th>IP</th>
          <th>Port</th>
          <th>Type</th>
          <th>State</th>
          <th class="text-center">Action</th>
        </tr>
        <tr v-for="(field, f) in baseline_items" :key="f">
          <td>
            <router-link :to="'/assets/scan/' + field.base_line.asset_id">
              {{ field.base_line.asset_name }}
            </router-link>
          </td>
          <td>
            <span variant="primary">
              {{ field.ip | replace("-", ".") }}
            </span>
          </td>
          <td>{{ field.port }}</td>
          <td>{{ field.port_type }}</td>
          <td>{{ field.port_state }}</td>
          <td class="text-center">
            <button
              @click="deleteBaseline(field.bl_entry_id)"
              type="button"
              class="btn btn-danger btn-sm mr-1"
              style="position: relative"
            >
              Delete
            </button>
            <b-dropdown size="sm" variant="outline-primary" text="Actions">
              <b-dropdown-item
                v-if="!field.is_approved"
                @click="approveBaseline(field.bl_entry_id)"
                >Approve</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!field.is_false_positive"
                @click="markAsFalsePositive(field.bl_entry_id)"
                >Mark as False Positive</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!field.is_approved"
                @click="
                  OpenMitigateModal(
                    field.bl_entry_id,
                    field.port,
                    field.ip,
                    field.base_line.asset_id
                  )
                "
              >
                Mitigate</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item @click="deleteBaseline(field.bl_entry_id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </td>
        </tr>
      </table>
      <div
        class="d-flex justify-content-end py-1"
        v-if="baseline_items.length > 9"
      >
        <router-link class="text-white" :to="'/dashboard/baseline-list'">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            style="position: relative"
          >
            See More >>
          </button>
        </router-link>
      </div>
    </div>
    <b-modal ref="modal-mitigate" title="Add Task" hide-footer size="lg">
      <p class="my-1">Add a task to mitigate this issue!</p>
      <TaskAddForm
        :asset_id="asset_id"
        :organization="org_id"
        :callBack="afterTaskAdd"
        :task="task_title"
        task_type="BASELINE"
      />
    </b-modal>
  </b-card-code>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import TaskAddForm from "../Asset/components/TaskAddForm.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    TaskAddForm,
    vSelect,
    // VBToggle,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      asset_id: "",
      organization_id: "",
      pagination_pos: "center",
      task_title: "",
      items: [],
      baseline_items: [],
      baseline_currentPage: 1,
      baseline_perPage: 10,
      baseline_rows: 0,
      multi_asset_filter: "",
      multi_asset_options: [],
      port_filter: null,
      // baseline_fields: [
      //   { key: "ip", label: "Ip" },
      //   { key: "port", label: "Port" },
      //   { key: "port_type", label: "Type" },
      //   { key: "port_state", label: "State" },
      // ],
    };
  },
  filters: {
    replace: function (ip, rep, repWith) {
      const result = ip.split(rep).join(repWith);
      return result;
    },
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
    group_filter_asset: {
      type: Number,
      required: true,
    },
    // tab_id: {
    //   type: String,
    //   required: true,
    // },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    // if (this.tab_id == "tab2") {
    //   debugger;
    //   this.load();
    // }
    // if (this.org_id != null) {
    //   this.load();
    // }
  },
  watch: {
    tabIndex: function (newVal) {
      this.load();
      if (newVal === 2) {
        this.load();
      }
    },
    // tab_id() {
    //   if (this.tab_id == "tab2") {
    //     this.load();
    //   }
    // },
    group_filter_asset() {
      this.load();
    },
  },
  methods: {
    load: function () {
      this.multi_asset_options = [];
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v1/baseline-deviation?org_id=" +
        this.org_id;
      if (this.group_filter_asset != null) {
        url = url + "&group_id=" + this.group_filter_asset;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };

      this.$http(options).then((res) => {
        // console.log(res.data.results.baseline_entries, "baseline dataaaa");
        // this.toastMessage(res)  // method call to method2
        this.baseline_items = res.data.results;

        // this.baseline_items = JSON.parse(res.data[0].baseline_entries);
        // this.baseline_items = res.data.base_line_entries;
      });

      // load multi asset
      // let url_asset =
      //   process.env.VUE_APP_BASEURL + "asset/asset?org_id=" + this.org_id;
      // if (this.group_filter_asset != null) {
      //   url = url_asset + "&group_id=" + this.group_filter_asset;
      // }
      // const o_options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url: url_asset,
      // };
      // var self = this;
      // this.$http(o_options).then((res) => {
      //   res.data.results.map(function (value, key) {
      //     let a = {
      //       value: res.data.results[key].asset_id,
      //       text: res.data.results[key].asset_name,
      //     };
      //     self.multi_asset_options.push(a);
      //   });
      // });
    },
    resetFn() {
      this.multi_asset_filter = "";
      this.port_filter = null;
      this.searchFn()
    },
    fetchOptions(search) {
      if (search.length > 2) {
        let url =
          process.env.VUE_APP_BASEURL +
          "asset/org-asset?org_id=" +
          this.org_id +
          "&name=" +
          search;
        if (this.group_filter_asset != null) {
          url = url_asset + "&group_id=" + this.group_filter_asset;
        }
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.multi_asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.multi_asset_options.push(a);
          });
        });
      }
    },
    searchFn: function () {
      this.filter = [];
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v1/baseline-deviation?org_id=" +
        this.org_id;
      if (this.multi_asset_filter.length != 0) {
        url = url + "&asset_id=" + this.multi_asset_filter;
        this.filter.push({ asset_id: this.multi_asset_filter });
      }
      if (this.port_filter != null) {
        url = url + "&port=" + this.port_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.baseline_items = res.data.results;
      });
    },
    deleteBaseline: function (id) {
      if (
        confirm(
          "Do you really want to delete this entry? We highly recommend to `mitigate` the issue before deleting the entry from baseline"
        )
      ) {
        let data = {
          pk: id,
        };
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          data: data,
          url: process.env.VUE_APP_BASEURL + "asset/baseline/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },
    approveBaseline: function (id) {
      if (confirm("Do you really want to approve this port?")) {
        let data = {
          pk: id,
          is_approved: true,
          is_false_positive: false,
        };
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/json" },
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/baseline/" +
            id +
            "/approve-baseline/", //+'scan/scan/'+id+'/refresh',
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },
    markAsFalsePositive: function (id) {
      if (confirm("Do you really want to mark this as false positive?")) {
        let data = {
          pk: id,
          is_false_positive: true,
          is_approved: false,
        };
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/json" },
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/baseline/" +
            id +
            "/approve-baseline/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },

    OpenMitigateModal: function (id, port, ip, asset_id) {
      this.task_title = "Please close port " + port + " of asset " + ip;
      (this.asset_id = asset_id), this.$refs["modal-mitigate"].show();
    },
    afterTaskAdd: function () {
      this.$refs["modal-mitigate"].hide();
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
</style>
<style lang="scss">
// @import "vue-select/src/scss/vue-select.scss";
@import "@core/scss/vue/libs/vue-select.scss";
// [dir] .vs--searchable .vs__dropdown-toggle {
//   padding-bottom: 6px !important;
//   padding-top: 4px !important;
//   background-color: white;
// }
// [dir="ltr"] .vs__search,
// [dir="ltr"] .vs__search:focus {
//   color: #6e6b7b;
// }

// .dark-layout .vs__search,
// [dir="ltr"] .vs__search:focus {
//   color: #b4b7bd;
// }
// .dark-layout .vs--searchable .vs__dropdown-toggle {
//   padding-bottom: 6px;
//   padding-top: 4px;
//   background-color: #283046;
// }
</style>
