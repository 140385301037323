var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"border-primary rounded"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('h4',{staticClass:"text-primary text-left"},[_vm._v("Asset Overview")]),_c('b-button',{staticClass:"mr-1",attrs:{"type":"button","variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.gotoaddAsset()}}},[_vm._v(" Add Asset ")])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { assettype: 0, group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.total_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.total_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Total")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { grouptype: 'external', group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.internet_facing_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.internet_facing_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v(" Internet Facing ")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { grouptype: 'internal', group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.internal_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.internal_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Internal")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: {  tabIndex: 1 },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.unmanaged_asset_count != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.unmanaged_asset_count)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Unmanaged")])])])],1)],1)],1)],1),_c('b-card',{staticClass:"border-primary rounded"},[_c('h4',{staticClass:"text-primary text-left"},[_vm._v("Asset Category")]),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { assettype: 2, group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.web_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.web_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Web")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { assettype: 1, group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.network_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.network_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Network")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { assettype: 4, group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.mobile_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.mobile_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("Mobile")])])]),_c('b-col',[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets',
                  query: { assettype: 3, group_id: _vm.groupid },
                })}}},[_c('span',{staticClass:"font-weight-bold"},[(_vm.assetCount.api_assets != 0)?_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":JSON.stringify(_vm.assetCount.api_assets)}}):_c('b-avatar',{attrs:{"size":"md","variant":"light-primary","text":"0"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("API")])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }