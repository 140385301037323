<template>
  <b-row>
    <b-col lg="12">
      <b-row>
        <b-col cols="" class=""
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
             @click="
              $router.push({
                path: '/assets',
                query: { grouptype: 'external' },
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.asset_based_data.total_external_asset_count
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Exposed Assets</span
            >
          </div></b-col
        >
        <b-col cols="" class=""
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/dashboard/domain-list',
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.asset_based_data.domains_subdomains
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Domains & Sub Domains</span
            >
          </div></b-col
        >
        <b-col cols="" class=""
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/assets/vulnerabilities',
                query: {
                  asm_filter: 1,
                  tabIndex: 1,
                },
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.vulnerability_count
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Open Vulnerabilities</span
            >
          </div></b-col
        >
        <b-col cols="" class=""
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/assets/vulnerabilities',
                query: {
                  asm_filter: 1,
                  tabIndex: 1,
                  source: 'SSL_EXPIRY',
                },
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.expired_certificates
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Expired Certificates</span
            >
          </div></b-col
        >
        <b-col cols="" class=""
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/assets/vulnerabilities',
                query: {
                  asm_filter: 1,
                  tabIndex: 1,
                  source: 'INSECUREPORT',
                },
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.insecure_ports
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Insecure Ports</span
            >
          </div></b-col
        >
        <b-col
          cols=""
          class=""
          v-b-tooltip.hover.v-info
          title="Breached - Not Addressed"
          ><div
            class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer"
            @click="
              $router.push({
                path: '/Discovery',
                query: { tabIndex: 2, breached: 1 },
              })
            "
          >
            <span class="h2 text-primary">{{
              attack_surface_data.breached_emails
            }}</span
            ><br /><span class="text-primary" style="font-size: 12px"
              >Breached Emails</span
            >
          </div></b-col
        >
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    attack_surface_data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.bg-blue-grad {
  /* background: linear-gradient(to bottom right,#825cf9,#8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
}

.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>