<template>
  <b-card no-body class="px-1 pb-1">
    <b-card-header>
      <b-card-title style="font-size: 14px" class="">Exposed Assets By Location</b-card-title>
    </b-card-header>
    <div class="row">
      <div class="col-8" style="position: sticky;">
        <l-map  :center="center" :attribution-control="true" :zoom-control="true" :scroll-wheel-zoom="false" minZoom=1>
          <l-tile-layer :url="url" />
          <!-- Loop through locations and create markers for each country -->
          <l-marker
            v-for="(location, index) in filteredLocations"
            :key="index"
            :lat-lng="[location.value.latitude, location.value.longitude]"
          >
            <l-icon>
              <b-img
                :src="require('@/assets/images/icons/location.png')"
                height="15"
              />
            </l-icon>
            <l-popup
              >{{ location.key }} - 
              {{ location.value.total_assets }}</l-popup
            >
          </l-marker>
        </l-map>
      </div>

      <div class="col-4 d-flex align-items-center" >
        <!-- List of locations -->
        <b-list-group class="list-group-circle text-left bg-transparent">
          <b-list-group-item
            v-for="(location, index) in locationData.slice(0, 7)"
            :key="index"
            class="bg-transparent"
          >
            {{ location.key }} - {{ location.value.total_assets }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup ,LIcon} from "vue2-leaflet";
import {
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BImg
} from "bootstrap-vue";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BImg
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 2, // Adjust zoom level as needed
      center: [20, 0], // Adjust center as needed
    };
  },
  props: {
    attackSurfaceLocation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locationData() {
      return Object.entries(this.attackSurfaceLocation).map(([key, value]) => ({
        key,
        value,
      }));
    },
    filteredLocations() {
      // Filter out locations with null total assets
      return this.locationData.filter(
        (location) => location.value.total_assets !== null
      );
    },
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.ex1 {
  height: 350px;
  overflow-y: scroll;
}
.leaflet-control-attribution.leaflet-control {
    display: none !important;
}
.leaflet-control-zoom {
  display: none !important;
}
</style>