
<template>
  <b-card no-body>
    <!-- {{ stats }} -->
    <b-card-header>
      <b-card-title class="text-capitalize"
        >Asset Group: {{ this.stats[this.group].groupname }}</b-card-title
      >
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0"
        size="sm"
      >
        <b-dropdown-item v-for="day in chartInfo.lastDays" :key="day">
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
      <b-form-select
        class="chart-dropdown"
        text="Last 7 Days"
        right
        style="width: 25%"
        toggle-class="p-0"
        size="sm"
        v-model="group"
        :options="group_filter"
      >
      </b-form-select>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="325"
        :options="productOrdersRadialBar.chartOptions"
        :series="series"
      />

      <!-- chart info -->

      <div
        v-for="(index, key) in this.stats[this.group]"
        :key="key"
        class="d-flex justify-content-between"
        :class="mb - 1"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            :class="
              key === 'groupname'
                ? 'display_none'
                : key === 'safe_assets'
                ? 'text-success'
                : key === 'critical_risk_assets'
                ? 'text-critical'
                : key === 'high_risk_assets'
                ? 'text-danger'
                : key === 'medium_risk_assets'
                ? 'text-warning'
                : key === 'low_risk_assets'
                ? 'text-primary'
                : key === 'risk_unknown'
                ? 'text-secondary'
                : ''
            "
          />
          <span
            :class="
              key === 'groupname'
                ? 'display_none'
                : 'font-weight-bold text-capitalize ml-75'
            "
            >{{
              key === "medium_risk_assets"
                ? "Medium Risk Assets"
                : key === "low_risk_assets"
                ? "Low Risk Assets"
                : key === "high_risk_assets"
                ? "High Risk Assets"
                : key === "critical_risk_assets"
                ? "Critical Risk Assets"
                : key === "safe_assets"
                ? "Safe Assets"
                : key === "risk_unknown"
                ? "Risk Unknown"
                : ""
            }}
          </span>
        </div>

        <span :class="key === 'groupname' ? 'display_none' : ''"
          >{{ index }} %</span
        >
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BFormSelect,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BFormSelect,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
    group_filter: {
      type: Array,
      required: true,
    },
  },

  watch: {
    group: function (newVal, oldVal) {
      var selected = this.stats[this.group];
      this.series = [
        selected.critical_risk_assets,
        selected.high_risk_assets,
        selected.medium_risk_assets,
        selected.low_risk_assets,
        selected.safe_assets,
        selected.risk_unknown,
      ];
    },
  },
  data() {
    return {
      chartInfo: {},
      group: 0,
      productOrdersRadialBar: {
        series: [70, 52, 26],
        chartOptions: {
          labels: ["Critical", "High", "Medium", "Low", "Safe", "Unknown"],
          plotOptions: {
            radialBar: {
              size: 200,
              hollow: {
                size: "10%",
              },
              track: {
                strokeWidth: "100%",
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: "1rem",
                  colors: "#5e5873",
                  fontWeight: "500",
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: "Total",
                  fontSize: "1.286rem",
                  colors: "#5e5873",
                  fontWeight: "500",

                  formatter(w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    // return SUM
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
          colors: [
            "#991D28",
            $themeColors.danger,
            $themeColors.warning,
            $themeColors.primary,
            $themeColors.success,
            $themeColors.secondary,
          ],
          stroke: {
            lineCap: "round",
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
    };
  },
  computed: {
    // a computed getter
    series: function () {
      var selected = this.stats[this.group];
      var c = [
        selected.critical_risk_assets,
        selected.high_risk_assets,
        selected.medium_risk_assets,
        selected.low_risk_assets,
        selected.safe_assets,
        selected.risk_unknown,
      ];
      console.log("c", c);
      return c;
    },
  },
};
</script>

<style scoped>
.display_none {
  display: none;
}
.text-critical {
  color: #991d28;
}
</style>