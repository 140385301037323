<template>
  <b-card no-body>
    <!-- {{vulnData}} -->
    <b-card-header class="pb-0">
      <b-card-title class="text-primary">Vulnerability Data</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-flex justify-content-center mt-1">
        <div class="d-flex">
          <span class="d-flex align-items-center p-1px"
            ><div class="box new mb-0 m-2px"></div>
            New & Open Vulnerability</span
          >
          <span class="d-flex align-items-center p-1px"
            ><div class="box closed mb-0 m-2px"></div>
            Closed Vulnerability</span
          >
          <!-- <span class="d-flex align-items-center p-1px"
            ><div class="box open mb-0"></div>
            Open Vulnerability</span
          > -->
        </div>
      </div>
      <b-row>
        <b-col>
          <e-charts
            class="w-100"
            ref="line"
            autoresize
            :options="chartOptionsLine"
            theme="theme-color"
            auto-resize
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
// import { $themeColors } from "@themeConfig";
import ECharts from "vue-echarts";
// import "echarts/lib/chart/bar";

import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import theme from './theme.json'
ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,

    ECharts,
  },
  props: {
    vulnData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chartOptionsLine: {
        title: {
          text: "Monthly Vulnerabilities",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Open", "New", "Closed"],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          // axisLabel: {
          //   rotate: 30,
          // },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Open",
            type: "line",
            data: [],
            color: ["#ff9149"],
          },
          {
            name: "New",
            type: "line",
            data: [],
            color: ["#127ac2"],
          },
          {
            name: "Closed",
            type: "line",
            data: [],
            color: ["#28d094"],
          },
        ],
      },
      // chartOptionsLine: {
      //   title: {
      //     text: "Monthly Vulnerabilities",
      //   },
      //   tooltip: {
      //     trigger: "axis",
      //   },
      //   legend: {
      //     data: ["Open", "New", "Closed"],
      //   },
      //   xAxis: {
      //     type: "category",
      //     boundaryGap: false,
      //     data: Object.keys(this.vulnData.vulnerability_chart.monthly_vulns),
      //   },
      //   yAxis: {
      //     type: "value",
      //   },
      //   series: [
      //     {
      //       name: "Open",
      //       type: "line",
      //       data: Object.values(this.vulnData.vulnerability_chart.monthly_vulns).map(
      //         (data) => data.open_vulns
      //       ),
      //       color: ["#ff9149"],
      //     },
      //     {
      //       name: "New",
      //       type: "line",
      //       data: Object.values(this.vulnData.vulnerability_chart.monthly_vulns).map(
      //         (data) => data.new_vulns
      //       ),
      //       color: ["#127ac2"],
      //     },
      //     {
      //       name: "Closed",
      //       type: "line",
      //       data: Object.values(this.vulnData.vulnerability_chart.monthly_vulns).map(
      //         (data) => data.closed_vulns
      //       ),
      //       color: ["#28d094"],
      //     },
      //   ],
      // },
      // chartOptionsLine: {
      //   xAxis: {
      //     data: ["Open Vulns", "New Vulns", "Closed Vulns"],
      //   },
      //   yAxis: {
      //     type: "value",
      //   },
      //   series: [
      //     {
      //       type: "line",
      //       data: [
      //         this.vulnData.open_vulns,
      //         this.vulnData.new_vulns,
      //         this.vulnData.closed_vulns,
      //       ],
      //     },
      //   ],
      //   title: {
      //     text: "Quarterly Sales Results",
      //     x: "center",
      //     textStyle: {
      //       fontSize: 24,
      //     },
      //   },
      //   color: ["#127ac2"],
      // },
    };
  },
  mounted() {
    this.updateChart();
  },
  watch: {
    vulnData: {
      handler: function () {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    updateChart() {
      const monthlyVulns =
        this.vulnData?.vulnerability_chart?.monthly_vulns || {};
      this.chartOptionsLine.xAxis.data = Object.keys(monthlyVulns);
      this.chartOptionsLine.series[0].data = Object.values(monthlyVulns).map(
        (data) => data.open_vulns
      );
      this.chartOptionsLine.series[1].data = Object.values(monthlyVulns).map(
        (data) => data.new_vulns
      );
      this.chartOptionsLine.series[2].data = Object.values(monthlyVulns).map(
        (data) => data.closed_vulns
      );
    },
  },
};
</script>
<style scoped>
.box {
  float: left;
  height: 10px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid rgb(255, 255, 255);
  clear: both;
}
.open {
  background-color: #ff9149;
}
.new {
  background-color: #127ac2;
}
.closed {
  background-color: #28d094;
}
.p-1px{
  padding-left: 2px;
  padding-right: 2px;
}
.m-2px{
  margin-left: 2px;
  margin-right:2px;
}
</style>
